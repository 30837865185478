




























































import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
export default mixins(DraftElement).extend({
  name: 'Carousel',
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      carousel: 0,
      carouselItem: new Array<Array<string>>()
    }
  },
  watch: {
    items () {
      this.generatePortfolioImages()
    }
  },
  mounted () {
    this.generatePortfolioImages()
  },
  methods: {
    generatePortfolioImages (): void {
      const n = 3
      const items = new Array<string>()
      this.data.images.forEach((img: any) => {
        items.push(`${this.appConfiguration.imageBaseUrl}${img.name}`)
      })
      this.carouselItem = new Array(Math.ceil(items.length / n))
        .fill('')
        .map(() => items.splice(0, n))
    }
  }
})
